import React from 'react';
import Layout from "../components/layout"
import linkedin from '../assets/linkedin.png'
import twitter from '../assets/twitter.png'
import mail from '../assets/mail.png'
import github from '../assets/github.png'
import linkedinAlt from '../assets/linkedinAlt.png'
import twitterAlt from '../assets/twitterAlt.png'
import mailAlt from '../assets/mailAlt.png'
import githubAlt from '../assets/githubAlt.png'

const Contact = () => (
  <Layout>
   <div className='info-container'>
      <div className='links'>
      <h1>Let's talk!</h1>
      <form name="contact" method="post" action="/contact" data-netlify="true" data-netlify-honeypot="bot-field">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact"/>
        <div>
          <div>Full Name</div>
          <input type="text" name="name" required/>
        </div>
        <div>
          <div>Contact #</div>
          <input type="tel" name="phone" required/>
        </div>
        <div>
          <div>Email Address</div>
          <input type="email" name="email" required/>
        </div>
        <div>
          <div>Any Additional Info?</div>
          <textarea className="addInfo" type="text" name="details" />
        </div>
        <div className="subBtnHldr">
          <button type="submit" className="subBtn">Send</button>
        </div>
      </form>
      <a href="https://www.linkedin.com/in/kevinsesouktran/" className='show'>
        <img src={linkedin} alt="Link to LinkedIn Profile" className='contactLinks'/>
        <img src={linkedinAlt} alt="Link to LinkedIn Profile" className='contactLinksAlt'/>
      </a>
      <a href="https://github.com/sesouk" className='show'>
        <img src={github} alt="Link to Github Profile" className='contactLinks'/>
        <img src={githubAlt} alt="Link to Github Profile" className='contactLinksAlt'/>
      </a>
      <a href="mailto:sesouk@gmail.com" className='show'>
        <img src={mail} alt="Link for e-Mail" className='contactLinks'/>
        <img src={mailAlt} alt="Link for e-Mail" className='contactLinksAlt'/>
      </a>
      <a href="https://twitter.com/kevindevelopin" className='show'>
        <img src={twitter} alt="Link to Twitter Profile" className='contactLinks'/>
        <img src={twitterAlt} alt="Link to Twitter Profile" className='contactLinksAlt'/>
      </a>
        </div>
      </div> 
  </Layout>
  );

export default Contact;